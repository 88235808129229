import React from "react";
import { Helmet } from "react-helmet-async";
import CCWalletSelect from "../components/cc-custom/CCWalletSelect";

import Card from "react-bootstrap/Card";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Home = () => {
  return (
    <Container>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Row>
        <Col xg={12}>
          <Card style={{ marginTop: 90, padding: 20 }}>
            <CCWalletSelect />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Home;
